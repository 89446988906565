import { memo } from 'react';

import { ImageType } from 'lux/models/articlePage';
import { ArticleTopics } from 'lux/models/articlePage/types';
import { useSelector } from 'src/hooks/useSelector';

import HeaderDefault from 'src/pages/Article/components/LandingHeader/HeaderDefault';
import HeaderInsider from 'src/pages/Article/components/LandingHeader/HeaderInsider';

const LandingHeader = () => {
    const title = useSelector((state) => state.articlePage?.title);
    const publicationTime = useSelector((state) => state.articlePage?.publicationTime);
    const images = useSelector((state) => state.articlePage?.images);
    const insiderDesignType = useSelector((state) => state.articlePage?.insiderDesignType);
    const topics = useSelector((state) => state.articlePage?.topics);

    const image =
        images &&
        images.find((img) => {
            return insiderDesignType === ImageType.Landing
                ? img.type === insiderDesignType
                : img.type === ImageType.Title;
        });

    const isInsider = topics && topics.some((topic) => topic.code === ArticleTopics.Insider);

    if (isInsider && insiderDesignType === ImageType.Landing) {
        return <HeaderInsider title={title} publicationTime={publicationTime} image={image} />;
    } else if (!isInsider) {
        return <HeaderDefault title={title} publicationTime={publicationTime} image={image} />;
    }
    return null;
};

export default memo(LandingHeader);

import { FC } from 'react';

import Column from 'bloko/blocks/column';
import VSpacing from 'bloko/blocks/vSpacing';

import ContainerForXSL from 'src/components/CodeInjector/ContainerForXSL';
import Portal from 'src/components/CodeInjector/Portal';
import { useSelector } from 'src/hooks/useSelector';
import ArticleEmployerInfo from 'src/pages/Article/components/EmployerInfo';
import LandingHeader from 'src/pages/Article/components/LandingHeader';
import Rating from 'src/pages/Article/components/Rating';
import ReadMore from 'src/pages/Article/components/ReadMore';
import SidebarOverPortal from 'src/pages/Article/components/Sidebar';
import useScrollAnalytics from 'src/pages/Article/hooks/useScrollAnalytics';

type Props = {
    articleId: string;
};

const BlogArticle: FC<Props> = ({ articleId }) => {
    const articleRating = useSelector((state) => state.articleRating);
    const readMoreArticles = useSelector((state) => state.articlePage?.readMore);
    const articleEmployerInfo = useSelector((state) => state.articleEmployerInfo);

    useScrollAnalytics(articleId);

    return (
        <>
            <ContainerForXSL place="legacy-page-layout-xsl" />
            <SidebarOverPortal />
            <Portal place="landingHeader">
                <LandingHeader />
            </Portal>
            {articleEmployerInfo && (
                <Portal place="articleEmployerInfo">
                    <ArticleEmployerInfo />
                </Portal>
            )}
            {articleRating && (
                <Portal place="articleRating">
                    <Column xs="4" s="8" m="8" l="12">
                        <VSpacing base={12} xs={8} />
                        <Rating
                            articleId={articleId}
                            voteState={articleRating.voteState}
                            likesCount={articleRating.likesCount}
                        />
                        <VSpacing base={4} xs={3} />
                    </Column>
                </Portal>
            )}
            {!!readMoreArticles?.length && (
                <Portal place="readMore">
                    <ReadMore readMoreArticles={readMoreArticles} />
                </Portal>
            )}
        </>
    );
};

export default BlogArticle;

import React, { FC, MouseEventHandler, useEffect, useState } from 'react';

import Analytics from '@hh.ru/analytics-js';
import {
    VkScaleSmallAppearanceColor,
    VkScaleSmallAppearanceDefault,
    FacebookScaleSmallAppearanceColor,
    FacebookScaleSmallAppearanceDefault,
    TwitterScaleSmallAppearanceColor,
    TwitterScaleSmallAppearanceDefault,
    OkScaleSmallAppearanceColor,
    OkScaleSmallAppearanceDefault,
    WhatsappScaleSmallAppearanceColor,
    WhatsappScaleSmallAppearanceDefault,
    ViberScaleSmallAppearanceColor,
    ViberScaleSmallAppearanceDefault,
    TelegramScaleSmallAppearanceColor,
    TelegramScaleSmallAppearanceDefault,
    IconColor,
} from 'bloko/blocks/icon';
import Share from 'bloko/blocks/share';

import { ShareButtonProps, SocialShareIcons } from 'lux/models/articlePage';
import { useSelector } from 'src/hooks/useSelector';

import { services } from 'src/pages/Article/components/ShareBar/services';

const IconMap = {
    [SocialShareIcons.Vk]: { color: VkScaleSmallAppearanceColor, default: VkScaleSmallAppearanceDefault },
    [SocialShareIcons.Facebook]: {
        color: FacebookScaleSmallAppearanceColor,
        default: FacebookScaleSmallAppearanceDefault,
    },
    [SocialShareIcons.Twitter]: {
        color: TwitterScaleSmallAppearanceColor,
        default: TwitterScaleSmallAppearanceDefault,
    },
    [SocialShareIcons.Ok]: { color: OkScaleSmallAppearanceColor, default: OkScaleSmallAppearanceDefault },
    [SocialShareIcons.Whatsapp]: {
        color: WhatsappScaleSmallAppearanceColor,
        default: WhatsappScaleSmallAppearanceDefault,
    },
    [SocialShareIcons.Viber]: { color: ViberScaleSmallAppearanceColor, default: ViberScaleSmallAppearanceDefault },
    [SocialShareIcons.Telegram]: {
        color: TelegramScaleSmallAppearanceColor,
        default: TelegramScaleSmallAppearanceDefault,
    },
};

const POPUP = {
    width: 500,
    height: 400,
};

const ShareButton: FC<ShareButtonProps> = ({ icon, url, type }) => {
    const topLevelDomain = useSelector((state) => state.topLevelDomain);
    const showMainArticleImage = useSelector((state) => state.articlePage?.showMainArticleImage);

    const [counter, setCounter] = useState<number | null>(0);

    useEffect(() => {
        const location = topLevelDomain
            ? `${window.location.protocol}//${topLevelDomain}${window.location.pathname}`
            : '';

        if (services[type]) {
            services[type].injectScript(location, setCounter);
        }
    }, [topLevelDomain, type]);

    const onClick: MouseEventHandler<HTMLAnchorElement> = (event) => {
        event.preventDefault();
        window.open(
            url,
            'socialShare',
            `resizable=yes,top=${(screen.height - POPUP.height) / 2},left=${(screen.width - POPUP.width) / 2},height=${
                POPUP.height
            },width=${POPUP.width},noopener`
        );
        Analytics.trackPageView(`virtual/click/social/article/${type}`);
    };

    const IconComponent = IconMap[icon][!showMainArticleImage ? 'color' : 'default'];

    return (
        <Share
            Element={'a'}
            href={url}
            icon={<IconComponent initial={!showMainArticleImage ? IconColor.Black : IconColor.White} />}
            onClick={onClick}
            count={counter && counter > 0 ? counter : undefined}
            inverted={showMainArticleImage}
        />
    );
};

export default ShareButton;

import { FC } from 'react';

import VSpacing from 'bloko/blocks/vSpacing';

import Portal from 'src/components/CodeInjector/Portal';
import VSeparator from 'src/components/VSeparator';
import { useSelector } from 'src/hooks/useSelector';
import SuitableVacancies from 'src/pages/Article/components/SuitableVacancies';
import VacanciesOfTheDay from 'src/pages/Article/components/VacanciesOfTheDay';

const Sidebar: FC = () => {
    const suitableVacanciesCollection = useSelector((state) => state.suitableVacancies?.vacancies);
    const vacanciesOfTheDayCollection = useSelector((state) => state.vacanciesOfTheDay?.vacancies);

    // (suitableVacanciesCollection?.length > 0) expression does not allow eslint
    const showSuitableVacancies = suitableVacanciesCollection && suitableVacanciesCollection.length > 0;
    const showVacanciesOfTheDay = vacanciesOfTheDayCollection?.length > 0;
    const showArticleSidebar = showSuitableVacancies || showVacanciesOfTheDay;

    if (!showArticleSidebar) {
        return null;
    }

    return (
        <Portal place="articleSidebar">
            {showSuitableVacancies && <SuitableVacancies collection={suitableVacanciesCollection} />}
            {showSuitableVacancies && showVacanciesOfTheDay && (
                <>
                    <VSpacing base={6} />
                    <VSeparator />
                    <VSpacing base={4} />
                </>
            )}
            {showVacanciesOfTheDay && <VacanciesOfTheDay collection={vacanciesOfTheDayCollection} />}
        </Portal>
    );
};

export default Sidebar;
